

$(document).ready(function () {
    if ($(".project-sub-item").hasClass("active")) {
        $(".project-sub-all").removeClass("active");
    }

    AOS.init({
        disable: "phone",
    });

    $(".news-slider").each(function () {
        $(this)
            .find(".slider-nav")
            .css({
                top: $(this).find(".img").height() / 2,
            });
    });

    $(window).resize(function () {
        $(".news-slider").each(function () {
            $(this)
                .find(".slider-nav")
                .css({
                    top: $(this).find(".img").height() / 2,
                });
        });
    });

    $(".form-subscription-fixed .close").on("click", function () {
        setCookie("isHidden", true, 1);
        $(".form-subscription-fixed").hide();
    });

    if (getCookie("isHidden") === true) {
        $(".form-subscription-fixed").hide();
    }

    if(getCookie("isHidden") === null) {
        setTimeout(() => {
            $(".form-subscription-fixed").show();
        }, 2000);
    }

    // eraseCookie("isHidden")
    // $(".bst-detail-author-content").multiTextToggleCollapse({
    //   line: 4,
    // });
});

document.addEventListener("contextmenu", (event) => event.preventDefault());

function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
    document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
}

function getCookie(key) {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
}

function eraseCookie(key) {
    var keyValue = getCookie(key);
    setCookie(key, keyValue, "-1");
}

const leftMenuMobileMapping = new MappingListener({
    selector: ".left-menu",
    mobileWrapper: ".mobile-menu",
    mobileMethod: "appendTo",
    desktopWrapper: ".left-header",
    desktopMethod: "appendTo",
    breakpoint: 768.98,
}).watch();

const rightMenuMobileMapping = new MappingListener({
    selector: ".right-menu",
    mobileWrapper: ".mobile-menu",
    mobileMethod: "appendTo",
    desktopWrapper: ".right-header",
    desktopMethod: "prependTo",
    breakpoint: 768.98,
}).watch();

var home3Swiper = new Swiper(".home-3 .swiper-container", {
    slidesPerView: 2,
    initialSlide: 1,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    navigation: {
        prevEl: ".home-3 .swiper-prev",
        nextEl: ".home-3 .swiper-next",
    },
    breakpoints: {
        1024.98: {
            slidesPerView: 1.5,
        },
        768.98: {
            slidesPerView: 1,
            centeredSlides: false,
        },
    },
});
try {
    var bstDetailThumbnail = new Swiper(
        ".bst-detail-thumbnail .swiper-container",
        {
            spaceBetween: 20,
            slidesPerView: 3,
            observer: true,
            observeParents: true,
            navigation: {
                prevEl: ".bst-detail-2 .swiper-prev",
                nextEl: ".bst-detail-2 .swiper-next",
            },
        }
    );

    var bstDetailImages = new Swiper(".bst-detail-images .swiper-container", {
        spaceBetween: 20,
        navigation: {
            prevEl: ".bst-detail-2 .swiper-prev",
            nextEl: ".bst-detail-2 .swiper-next",
        },
        observer: true,
        observeParents: true,
        thumbs: {
            swiper: bstDetailThumbnail,
        },
    });
} catch (error) {}

var bstAuthorImages = new Swiper(
    ".bst-detail-author-images .swiper-container",
    {
        spaceBetween: 50,
        slidesPerView: 4,
        navigation: {
            prevEl: ".bst-detail-author-images .swiper-prev",
            nextEl: ".bst-detail-author-images .swiper-next",
        },
        breakpoints: {
            1200: {
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
        },
    }
);
var related_work = new Swiper(".related-work .swiper-container", {
    navigation: {
        prevEl: ".related-work .swiper-prev",
        nextEl: ".related-work .swiper-next",
    },
    slidesPerView: 4,
    spaceBetween: 50,
    breakpoints: {
        1024: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.75,
            centeredSlides: true,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 1.25,
            centeredSlides: true,
            spaceBetween: 20,
        },
    },
});
